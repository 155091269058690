import React from 'react'
import { graphql } from 'gatsby'
import Navbar from '../sections/Navbar/Navbar'
import Footer from '../sections/Footer'
import { PrismicRichText } from "@prismicio/react";
import "../styles/TermsAndConditions.scss";

const TermsAndConditions = ({ data }: any) => {
  const info = data.prismicLegalDoc.dataRaw
  return (
    <div>
      <Navbar />
      <div className="TermsAndConditions">
        <div className='TermsAndConditions-title'>
          <PrismicRichText field={info.title} />
        </div>
        <div className="TermsAndConditions-top">
          <PrismicRichText field={info.text_body} />
        </div>
        <div className='TermsAndConditions-second-title'>
          <PrismicRichText field={info.second_title} />
        </div>
        <div>
          <PrismicRichText field={info.global_text} />
        </div>
        <div>
          <img className="Logo_coachello_tac" alt="logo_coachello" src={data.prismicLegalDoc.data.logo_coachello.url} />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default TermsAndConditions;


export const query = graphql`
query TermsAndConditions  {
  prismicLegalDoc(uid: {eq: "terms-and-conditions"}, lang: {eq: "en-us"}) {
    uid
    dataRaw
    lang
    url
    data {
      logo_coachello {
        url
      }
    }
  }
}
`;
